"use client";
import { useState, useEffect, RefObject } from "react";

export default function useElementSize(elem: RefObject<HTMLDivElement> | null) {
  const [elementSize, setElementSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      if (!elem?.current) return;
      setElementSize({
        width: elem.current.clientWidth,
        height: elem.current.clientHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [elem]);

  return elementSize;
}
